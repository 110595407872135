import httpService from '../../../helpers/http.service'

export default {
    getAll(params) {
        return httpService.get(`liquidations/commissions`, {params})
    },
    get(id = null, params = {}) {
        return httpService.get(`liquidations/commissions${id ? '/' + id : '' }`, {params})
    },
    delete(id) {
      return httpService.delete(`liquidations/commissions/${id}`);
    },
    save(params, id = null) {
        if(id) {
            return httpService.put(`liquidations/commissions/${id}`, params);

        }
        return httpService.post(`liquidations/commissions`, params);
    },
    deleteRow(id) {
        return httpService.delete(`liquidations/concepts/${id}/delete`)
    },
    updateRow(id,params) {
        return httpService.put(`liquidations/concepts/${id}/update`, params)
    },
    generateLiq(id) {
        return httpService.post(`liquidations/concepts/${id}/generate`)
    },
    changeStatus(id,params) {
        return httpService.post(`liquidations/status/${id}`, params)
    },
    addOthers(id,params){
        return httpService.post(`liquidations/others/values/${id}/add`, params)
    },
    addLoans(id,params){
        return httpService.post(`liquidations/loans/${id}/add`, params)
    },
    getPDF(id){
        return httpService.get(`reports/liquidations/${id}/export`)
    },
    deleteOthers(id){
        return httpService.delete(`liquidations/others/values/${id}/delete`)
    },

deleteLoans(id){
    return httpService.delete(`liquidations/loans/${id}/delete`)
}
}
